<template>
  <form @submit.prevent="resetPassword">
    <div v-if="!code || !email">
      <h3>
        Neplatný email alebo kód
      </h3>
    </div>
    <div v-if="done">
      <h3>
        Hotovo!
      </h3>
    </div>

    <div v-if="code && email && !done">
      <div class="d-flex flex-wrap align-items-center w-100 justify-content-center mb-5">
        <div class="col-md-2 mb-3 mb-md-0 d-flex justify-content-center justify-content-md-start pl-md-0">
          <z-back-button class="float-left" @click.native="back" />
        </div>
        <div class="col-12 col-md-8">
          <h3 class="mb-0">
            Reset hesla
          </h3>
        </div>
        <div class="col-md-2"></div>
      </div>

      <w-input v-model="$v.password" label="Heslo" type="password" />
      <w-input v-model="$v.confirmPassword" label="Zopakujte heslo" type="password" />
      <b-button
          pill variant="success"
          class="z-submit-btn mt-3 float-right"
          :disabled="$v.$invalid" type="submit"
      >Odoslať</b-button>
    </div>
  </form>
</template>

<script>
  import { required, sameAs, minLength } from 'vuelidate/lib/validators'

  export default {

    components: {
    },

    mounted() {
      this.code = this.$route.query.code
      this.email = this.$route.query.email
    },

    data() {
      return {
        password: null,
        confirmPassword: null,
        code: '',
        email: '',
        done: false
      }
    },

    validations(){
      return{
        password: { required, minLength: minLength(8) },
        confirmPassword: { required, sameAsPassword: sameAs('password') }
      }
    },

    methods: {
      async resetPassword() {
        if (this.$v.invalid) {
          return
        }

        const credentials = {
          email: this.email,
          code: this.code,
          password: this.password,
          password_confirmation: this.confirmPassword
        }

        this.done = await this.$store.dispatch('wAuth/resetPassword', credentials)
        this.$forceUpdate()
      },

      back() {
        this.$router.go(-1)
      }
    }

  }
</script>

